import { GrantsQuery } from "@/../gatsby-graphql"

export function getSEODescription(
  charitiesList?: GrantsQuery["ourGrantProcessJson"]["charitiesList"] | null
) {
  return (
    charitiesList &&
    charitiesList.values &&
    charitiesList.values
      .map(value => {
        if (value && value.description) {
          return value.description
        }
        return ``
      })
      .join(` `)
  )
}

export function getSEOImage(
  hero?: GrantsQuery["ourGrantProcessJson"]["hero"] | null
) {
  return hero && hero.image
}
