import React, { ReactElement } from "react"
import { CharitiesWeSupportJsonFeaturedCharitiesCarousel } from "@/../gatsby-graphql"
import { Carousel } from "../Carousel"
import { FeaturedCharitiesCarouselItem } from "./FeaturedCharitiesCarouselItem"
import HeroDot from "../Carousel/HeroDot"

interface Props {
  carouselItems: CharitiesWeSupportJsonFeaturedCharitiesCarousel[]
}

export function FeaturedCharitiesCarousel({
  carouselItems,
}: Props): ReactElement {
  return (
    <Carousel autoPlaySpeed={10000} pauseOnHover customDot={<HeroDot />}>
      {carouselItems.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <FeaturedCharitiesCarouselItem key={item.description + i} {...item} />
      ))}
    </Carousel>
  )
}
