import React, { ReactElement } from "react"
import classnames from "classnames"
import Markdown from "react-markdown"
import { CharitiesWeSupportJsonPageHeader } from "@/../gatsby-graphql"

import { charitiesHeaderShort } from "./styles.module.scss"

interface Props {
  pageHeader: CharitiesWeSupportJsonPageHeader
}

export function CharitiesPageHeader({ pageHeader }: Props): ReactElement {
  return (
    <div
      className={classnames(
        `flex flex-col justify-center align-middle text-center py-5`,
        `sm:py-8`,
        `md:py-15 md:pb-12`
      )}
    >
      {pageHeader.top && (
        <p
          className={classnames(
            `font-bold text-citrine-gray-dark text-xs`,
            `sm:text-base`,
            `lg:text-2xl`
          )}
        >
          {pageHeader.top}
        </p>
      )}
      {pageHeader.bottom && (
        <h1>
          <Markdown
            linkTarget="_blank"
            className={classnames(
              charitiesHeaderShort,
              `markdown colored-italics text-citrine-gray-dark text-2xl`,
              `sm:text-4xl`,
              `lg:text-5xl`
            )}
          >
            {pageHeader.bottom}
          </Markdown>
        </h1>
      )}
    </div>
  )
}
