import React, { ReactElement, useContext } from "react"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import { PreviewContext } from "@context/previewContext"
import GatsbyImagePreview from "../PreviewMode/GatsbyImagePreview/GatsbyImagePreview"
import { doesImageExist, Image } from "../../utils/image"

interface Props extends Omit<GatsbyImageProps, "image"> {
  image?: Image["src"] | string
}

function CustomGatsbyImage(props: Props): ReactElement {
  const { previewMode } = useContext(PreviewContext)
  const { image } = props
  if (!image) {
    return null
  }
  if (previewMode || typeof image === `string`) {
    return <GatsbyImagePreview {...props} />
  }
  if (doesImageExist(image)) {
    return (
      <GatsbyImage {...props} image={image.childImageSharp.gatsbyImageData} />
    )
  }
}

export default CustomGatsbyImage
