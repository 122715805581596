import classNames from "classnames"
import React, { ReactElement } from "react"
import { Image } from "../../utils/image"
import CustomGatsbyImage from "../CustomGatsbyImage/CustomGatsbyImage"
import { charityImage, charityItem } from "./styles.module.scss"

interface Props {
  image: Image
}

export function CarouselSlideItem({ image }: Props): ReactElement {
  return (
    <div
      className={classNames(
        `pt-8 pb-12 md:pt-12 md:pb-16 lg:pt-14 lg:pb-18 mx-5 md:mx-10 xl:mx-12`,
        charityItem
      )}
    >
      <div className="h-20 sm:h-24 md:h-28 lg:h-32 flex justify-center">
        <CustomGatsbyImage
          className={classNames(
            charityImage,
            `inline-flex relative transform -translate-y-1/2 top-1/2 max-w-full max-h-full h-full`
          )}
          image={image.src}
          alt={image.alt}
          imgStyle={{ objectFit: `scale-down` }}
        />
      </div>
    </div>
  )
}
