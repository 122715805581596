import React, { ReactElement } from "react"
import { DotProps } from "react-multi-carousel"
import classnames from "classnames"

function HeroDot({ active, onClick, index }: DotProps): ReactElement {
  return (
    <li>
      <button
        type="button"
        value={index}
        tabIndex={0}
        aria-label={`slide item ${index + 1}`}
        onKeyPress={({ key }) => {
          if ([` `, `Enter`].includes(key) && onClick) {
            onClick()
          }
        }}
        onClick={onClick}
        className={classnames(
          `cursor-pointer w-4 h-4 rounded-full shadow-sm inline-block bg-white hover:opacity-100 transition-opacity`,
          { "bg-white": active, "opacity-30": !active }
        )}
      />
    </li>
  )
}

export default HeroDot
