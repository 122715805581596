import classNames from "classnames"
import React from "react"

interface Props {
  error: string
  className?: string
}

export function FormError({ error, className = `` }: Props) {
  return (
    <div
      className={classNames(
        `text-red-500 text-2xs font-bold`,
        `sm:text-xs`,
        `xl:text-base`,
        className
      )}
    >
      {error}
    </div>
  )
}
