import { AboutJsonStaff } from "@/../gatsby-graphql"
import classnames from "classnames"
import React, { ReactElement } from "react"
import { staffGradient } from "./staffStyles.module.scss"

type Props = AboutJsonStaff

export function BODAndStaff({ staffMembers, title }: Props): ReactElement {
  return (
    <div className={classnames(staffGradient)}>
      <div className="container">
        <h1
          className={classnames(
            `flex text-center justify-center pt-4 pb-2 px-12 text-lg text-citrine-primary-medium font-extrabold`,
            `sm:text-2xl sm:pt-5 sm:pb-3`,
            `md:text-4xl md:pt-6 md:pb-4`,
            `lg:text-5xl lg:pt-8 lg:pb-6`,
            `xl:pt-14`,
            `2xl:pt-15 2xl:pb-8`
          )}
        >
          {title}
        </h1>
        <div
          className={classnames(`flex flex-wrap pb-6 px-6`, `md:px-8 md:pb-8`)}
        >
          {staffMembers &&
            staffMembers.map(
              (staffMember, i) =>
                staffMember && (
                  <div
                    className={classnames(
                      `flex flex-col flex-50% py-4 min-h-16 justify-center items-center font-bold border-citrine-primary-medium`,
                      `md:py-6 md:h-20 md:flex-33%`,
                      `lg:py-8 lg:h-24`,
                      `xl:h-32`,
                      `2xl:h-36`,
                      {
                        "border-l border-t-0": (i % 2) + 1 === 2 && i + 1 < 3,
                        "border-t border-l-0": (i % 2) + 1 === 1 && i + 1 >= 3,
                        "border-l border-t": (i % 2) + 1 === 2 && i >= 3,
                        "md:border-l md:border-t-0":
                          ((i % 3) + 1 === 2 || (i % 3) + 1 === 3) &&
                          i + 1 <= 3,
                        "md:border-t md:border-l-0":
                          (i % 3) + 1 === 1 && i + 1 > 3,
                        "md:border-l md:border-t":
                          ((i % 3) + 1 === 2 || (i % 3) + 1 === 3) && i + 1 > 3,
                      }
                    )}
                    key={staffMember.name + staffMember.position}
                  >
                    <h2
                      className={classnames(
                        `text-xs`,
                        `sm:text-sm`,
                        `lg:text-lg`,
                        `xl:text-2xl`,
                        `2xl:text-3xl`
                      )}
                    >
                      {staffMember.name}
                    </h2>
                    <p
                      className={classnames(
                        `text-center text-2xs`,
                        `sm:text-xs`,
                        `md:text-2xs`,
                        `lg:text-xs`,
                        `xl:text-sm`,
                        `2xl:text-base`
                      )}
                    >
                      {staffMember.position}
                    </p>
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  )
}
