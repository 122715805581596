import React, { ReactElement } from "react"
import classnames from "classnames"

import { MatchedGiftProgramJsonGiftQualifications } from "@/../gatsby-graphql"

type Props = MatchedGiftProgramJsonGiftQualifications

export function GiftsQualifications({
  title,
  qualifications,
}: Props): ReactElement {
  return (
    <div className={classnames(`flex flex-col`)}>
      {title && (
        <h2
          className={classnames(
            `px-4 pb-8 text-2xl leading-tight font-normal italic text-center text-citrine-primary-medium`,
            `sm:text-xl sm:leading-tight pb-10`,
            `md:text-2xl md:leading-tight`,
            `lg:text-3xl lg:leading-tight lg:px-16 lg:pb-12`,
            `xl:text-4xl xl:leading-tight xl:px-20 xl:pb-14`,
            `2xl:text-5xl 2xl:leading-tight 2xl:pb-16 2xl:pt-2`
          )}
        >
          {title}
        </h2>
      )}
      {qualifications && (
        <ul
          className={classnames(
            `grid grid-cols-1 gap-10 mb-15 justify-items-center auto-rows-fr`,
            `sm:grid-cols-3 sm:gap-6 sm:auto-rows-max`,
            `lg:mb-10 lg:gap-10`,
            `xl:mb-16 xl:gap-12`,
            `2xl:gap-15`
          )}
        >
          {qualifications.map(({ item }) => (
            <li
              key={item}
              className={classnames(
                `h-full max-w-72 text-sm font-normal p-8 bg-citrine-primary-medium text-citrine-gray-dark`,
                `sm:p-6 sm:max-w-48 sm:text-2xs sm:leading-tight sm:m-0`,
                `md:text-xs md:max-w-64`,
                `lg:text-base lg:max-w-72 lg:p-7 lg:leading-tight`,
                `xl:text-xl xl:max-w-84 xl:p-7 xl:leading-snug`,
                `2xl:text-2xl 2xl:max-w-96 2xl:p-8 2xl:leading-snug`
              )}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
