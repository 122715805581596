import React, { ReactElement } from "react"
import { OurGrantProcessJsonCharitiesList } from "@/../gatsby-graphql"
import {
  blueGradient,
  cyanGradient,
  orangeGradient,
  purpleGradient,
} from "./styles.module.scss"

import { GrantsCharitiesListItem } from "./GrantsCharitiesListItem"

interface Props {
  charitiesList: OurGrantProcessJsonCharitiesList
}
const gradients = [blueGradient, cyanGradient, orangeGradient, purpleGradient]
export function GrantsCharitiesList({ charitiesList }: Props): ReactElement {
  return (
    <>
      {charitiesList.values &&
        charitiesList.values.map((value, i) => (
          <GrantsCharitiesListItem
            // eslint-disable-next-line react/no-array-index-key
            key={value.description + i}
            {...value}
            isColoured={charitiesList.coloured}
            isReversed={i % 2 === 1}
            gradient={gradients[i % 4]}
          />
        ))}
    </>
  )
}
