import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import { HomeJsonLinkCardsCards as LinkCardType } from "@/../gatsby-graphql"
import { linkCardBoxShadow, linkCardHover } from "./LinkCard.module.scss"
import CustomGatsbyImage from "../CustomGatsbyImage/CustomGatsbyImage"

type Props = LinkCardType

function LinkCard({ linkLocation, linkText, image }: Props) {
  return (
    <Link
      className={classnames(
        linkCardBoxShadow,
        linkCardHover,
        `w-full max-w-xs mb-7 block`,
        `md:w-3/10 md:max-w-md`,
        `lg:max-w-xs`,
        `xl:max-w-sm`
      )}
      to={linkLocation}
    >
      <CustomGatsbyImage
        className={classnames(`h-4/5 w-full`)}
        image={image.src}
        alt={image.alt}
      />
      <div
        className={classnames(
          `bg-citrine-primary-medium h-14 w-full text-citrine-gray-medium font-bold justify-center items-center flex px-2`,
          `sm:h-16`,
          `lg:h-20`
        )}
      >
        <p
          className={classnames(
            `text-lg`,
            `sm:text-xl`,
            `md:text-base md:leading-tight`,
            `xl:text-xl`
          )}
        >
          {linkText}
        </p>
      </div>
    </Link>
  )
}

export default LinkCard
