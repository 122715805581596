import classnames from "classnames"
import React, { ReactElement } from "react"
import { Input } from "@utils"
import { FormError } from "./FormError"

interface Props
  extends Input,
    Pick<
      React.InputHTMLAttributes<HTMLTextAreaElement | HTMLInputElement>,
      "onChange" | "placeholder" | "name" | "required" | "className"
    > {
  inputClassName?: string
  errorClassName?: string
  type?: "input" | "textarea"
}

export function FormControl({
  error,
  className,
  inputClassName,
  errorClassName,
  type = `input`,
  ...restProps
}: Props): ReactElement {
  const InputEl = type
  return (
    <div className={classnames(`w-full mb-3 2xl:mb-5`, className)}>
      <InputEl
        className={classnames(
          {
            error: !!error,
          },
          `basic-input`,
          inputClassName
        )}
        type="text"
        {...restProps}
      />
      {!!error && (
        <FormError error={error} className={classnames(errorClassName)} />
      )}
    </div>
  )
}
