import * as React from "react"
import { PageProps } from "gatsby"
import classnames from "classnames"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { Hero } from "@components/Hero"
import { ContactUsForm } from "@components/ContactUsForm"
import { ContactUsGetInTouch } from "@components/ContactUsGetInTouch"

import { ContactUsQuery } from "@/../gatsby-graphql"
import { IPreviewContext } from "@context/previewContext"
import ContextWrapper from "./ContextWrapper"

type Props = PageProps<ContactUsQuery> & IPreviewContext

function ContactUsPage({ location, data, ...rest }: Props) {
  const {
    hero,
    getInTouch,
    sendButton,
    fields: { slug },
  } = data.contactUsJson
  return (
    <ContextWrapper {...rest}>
      <Layout location={location}>
        <SEO
          title={hero && hero.title}
          description={
            getInTouch && `${getInTouch.title} ${getInTouch.description}`
          }
          slug={slug}
        />
        {hero && <Hero {...hero} />}
        <div
          className={classnames(`p-6 container`, `sm:flex sm:p-10`, `xl:p-16`)}
        >
          {getInTouch && <ContactUsGetInTouch {...getInTouch} />}
          {sendButton && <ContactUsForm sendButton={sendButton} />}
        </div>
      </Layout>
    </ContextWrapper>
  )
}

export default ContactUsPage
