import React, { ReactElement, useContext } from "react"
import classnames from "classnames"

import { OurGrantProcessJsonCriteria } from "@/../gatsby-graphql"
import { PreviewContext } from "@context/previewContext"

interface Props {
  criteria: OurGrantProcessJsonCriteria
}

export function GrantsCriteria({ criteria }: Props): ReactElement {
  const { getAsset, previewMode } = useContext(PreviewContext)
  return (
    <div>
      {criteria.title && (
        <div className="flex w-full items-center">
          <h2
            className={classnames(
              `text-base p-6 w-full text-citrine-primary-medium text-center font-extrabold`,
              `sm:text-lg sm:py-8`,
              `lg:text-2xl lg:pt-16 lg:pb-9 lg:px-0`,
              `xl:text-3xl xl:pt-16 xl:pb-9`,
              `2xl:text-4xl 2xl:pt-20 2xl:pb-12`
            )}
          >
            {criteria.title}
          </h2>
        </div>
      )}
      {criteria.criteria && (
        <div
          className={classnames(
            `flex flex-col`,
            `sm:flex-row sm:flex-wrap`,
            `lg:px-10`,
            `xl:px-16`
          )}
        >
          {criteria.criteria.map(
            (criterion, i) =>
              criterion && (
                <div
                  className={classnames(
                    `flex flex-col items-center text-center w-full`,
                    `sm:w-1/2`,
                    `md:w-1/3`,
                    `lg:w-1/6`
                  )}
                  key={criterion.description || i}
                >
                  <img
                    className={classnames(
                      `w-32 h-32`,
                      `lg:w-28 lg:h-28`,
                      `xl:w-32 xl:h-32`,
                      `2xl:w-40 2xl:h-40`
                    )}
                    src={
                      previewMode
                        ? getAsset(criterion?.icon?.src)
                        : criterion?.icon?.src?.publicURL
                    }
                    alt={criterion?.icon?.alt}
                  />
                  {criterion.description && (
                    <p
                      className={classnames(
                        `text-center font-bold text-citrine-gray-dark py-4 px-10`,
                        `sm:py-6 sm:px-14`,
                        `lg:py-3 lg:px-5`,
                        `xl:py-3 xl:px-9`,
                        `2xl:py-4 2xl:px-6 2xl:text-lg`
                      )}
                    >
                      {criterion.description}
                    </p>
                  )}
                </div>
              )
          )}
        </div>
      )}
    </div>
  )
}
