import { useMediaPredicate } from "react-media-hook"
import defaultTheme from "tailwindcss/defaultTheme"

// sm: '640px',
// md: '768px',
// lg: '1024px',
// xl: '1280px',
// '2xl': '1536px',
function justSmaller(size: string) {
  return `${parseInt(size.slice(0, -2), 10) - 1}px`
}

function createMediaQuery(minWidth: string, maxWidth?: string) {
  const min = `(min-width: ${minWidth})`
  const max = maxWidth ? `(max-width: ${maxWidth})` : undefined
  if (max) {
    return `${min} and ${max}`
  }
  return min
}

export function useMediaQuery() {
  const isSmall = useMediaPredicate(
    createMediaQuery(
      defaultTheme.screens.sm,
      justSmaller(defaultTheme.screens.md)
    )
  )
  const isMedium = useMediaPredicate(
    createMediaQuery(
      defaultTheme.screens.md,
      justSmaller(defaultTheme.screens.lg)
    )
  )
  const isLarge = useMediaPredicate(
    createMediaQuery(
      defaultTheme.screens.lg,
      justSmaller(defaultTheme.screens.xl)
    )
  )
  const isXl = useMediaPredicate(
    createMediaQuery(
      defaultTheme.screens.xl,
      justSmaller(defaultTheme.screens[`2xl`])
    )
  )
  const is2xl = useMediaPredicate(createMediaQuery(defaultTheme.screens[`2xl`]))
  const isMobile = !isSmall && !isMedium && !isLarge && !isXl && !is2xl
  return { isMobile, isSmall, isMedium, isLarge, isXl, is2xl }
}
