import React, { ReactElement } from "react"
import classnames from "classnames"
import Markdown from "react-markdown"

import { OurGrantProcessJsonHowToBeConsidered } from "@/../gatsby-graphql"
import { LinkButton } from "../Button"

interface Props {
  howToBeConsidered: OurGrantProcessJsonHowToBeConsidered
}

export function GrantsHowToBeConsidered({
  howToBeConsidered,
}: Props): ReactElement {
  return (
    <div
      className={classnames(
        `m-10 mb-0 p-7 text-citrine-gray-dark border-citrine-primary-medium border-4 flex flex-col text-center`,
        `md:mx-16 md:px-14`,
        `lg:mx-40 lg:py-10 lg:border-8`,
        `xl:mx-44 xl:py-12`,
        `2xl:mx-52 2xl:px-28`
      )}
    >
      {howToBeConsidered.title && (
        <h2
          className={classnames(
            `leading-tight mb-2 font-bold text-lg`,
            `sm:text-2xl`,
            `md:text-3xl md:mb-0`,
            `lg:text-4xl`,
            `xl:text-5xl`
          )}
        >
          {howToBeConsidered.title}
        </h2>
      )}
      {howToBeConsidered.description && (
        <p
          className={classnames(
            `font-semibold text-xs mb-3`,
            `lg:text-sm lg:mb-4`,
            `xl:text-base`,
            `2xl:text-lg`
          )}
        >
          {howToBeConsidered.description}
        </p>
      )}
      {howToBeConsidered.submission && (
        <div className="flex flex-col items-center">
          {howToBeConsidered.submission.title && (
            <h3
              className={classnames(
                `leading-tight my-2 text-citrine-primary-medium text-xl font-extrabold`,
                `sm:text-2xl sm:max-w-84`,
                `md:text-3xl md:mb-0 md:max-w-120`,
                `lg:text-4xl`,
                `xl:text-5xl xl:max-w-160`
              )}
            >
              {howToBeConsidered.submission.title}
            </h3>
          )}
          {howToBeConsidered.submission.description && (
            <Markdown
              linkTarget="_blank"
              allowedElements={[`p`, `br`]}
              className={classnames(
                `markdown`,
                `font-semibold text-xs mb-3`,
                `lg:text-sm lg:mb-4`,
                `xl:text-base`,
                `2xl:text-lg`
              )}
            >
              {howToBeConsidered.submission.description}
            </Markdown>
          )}
          {howToBeConsidered.submission.notice && (
            <p
              className={classnames(
                `font-semibold text-xs mb-3`,
                `sm:mb-4`,
                `lg:text-sm lg:mb-6`,
                `xl:text-base xl:mb-7`,
                `2xl:text-lg`
              )}
            >
              {howToBeConsidered.submission.notice}
            </p>
          )}
          {howToBeConsidered.submission.callToAction &&
            howToBeConsidered.submission.callToAction.linkLocation &&
            howToBeConsidered.submission.callToAction.content && (
              <LinkButton
                to={howToBeConsidered.submission.callToAction.linkLocation}
                differentTab
              >
                {howToBeConsidered.submission.callToAction.content}
              </LinkButton>
            )}
        </div>
      )}
    </div>
  )
}
