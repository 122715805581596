import React, { ReactElement } from "react"
import MultiCarousel, { CarouselProps } from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

interface Props extends Partial<CarouselProps> {
  children: React.ReactChild[]
}

const defaultResponsive: CarouselProps["responsive"] = {
  all: {
    items: 1,
    breakpoint: { max: 5000, min: 0 },
  },
}

function Carousel({
  children,
  responsive = defaultResponsive,
  ...props
}: Props): ReactElement {
  return (
    <MultiCarousel
      responsive={responsive}
      deviceType="all"
      className="custom-carousel"
      ssr
      autoPlay
      autoPlaySpeed={5000}
      swipeable
      infinite
      arrows
      showDots
      draggable
      dotListClass="custom-dots-container"
      {...props}
    >
      {children}
    </MultiCarousel>
  )
}

export default Carousel
