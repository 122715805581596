import React from "react"
import classnames from "classnames"

import { HomeJsonLinkCardsCards as LinkCardType } from "@/../gatsby-graphql"

import { LinkCard } from "../LinkCard"

interface Props {
  linkCards?: LinkCardType[]
}

function LinkCards({ linkCards }: Props) {
  return (
    <div
      className={classnames(
        `flex flex-col items-center mt-4 px-4`,
        `sm:mt-6`,
        `md:flex-row md:justify-around md:mt-8 md:px-0`,
        `lg:mt-10`,
        `xl:mt-12`
      )}
    >
      {linkCards?.map(({ image, linkLocation, linkText }) => (
        <LinkCard
          key={linkLocation + linkText}
          image={image}
          linkLocation={linkLocation}
          linkText={linkText}
        />
      ))}
    </div>
  )
}

export default LinkCards
