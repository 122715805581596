import React from "react"
import classnames from "classnames"
import { CharitiesWeSupportJsonCharitiesListValues } from "@/../gatsby-graphql"
import CustomGatsbyImage from "@components/CustomGatsbyImage/CustomGatsbyImage"

interface Props extends CharitiesWeSupportJsonCharitiesListValues {
  isReversed: boolean
}

export function CharitiesListItem({
  description,
  descriptionNote,
  icon,
  image,
  title,
  linkLocation,
  isReversed,
}: Props) {
  return (
    <div
      className={classnames(`flex flex-col-reverse sm:items-center`, {
        "sm:flex-row-reverse": isReversed,
        "sm:flex-row": !isReversed,
      })}
    >
      <div className={classnames(`sm:w-1/2`)}>
        <CustomGatsbyImage
          className="w-full h-full"
          image={image.src}
          alt={image.alt}
        />
      </div>
      <div
        className={classnames(
          `h-72 p-7 flex flex-col justify-center py-0`,
          `sm:h-full sm:w-1/2 py-5`,
          `md:p-8`,
          `lg:p-10`,
          `xl:p-14`,
          `2xl:p-20`
        )}
      >
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={linkLocation} rel="noopener" target="_blank">
          <div>
            <CustomGatsbyImage
              className="max-w-6/10 max-h-32"
              image={icon.src}
              alt={icon.alt}
            />
          </div>
        </a>
        <p
          className={classnames(
            `font-bold text-citrine-gray-dark text-xs pt-4 pb-2`,
            `md:text-sm`,
            `lg:text-lg lg:pt-6 lg:pb-3`,
            `xl:text-2xl`,
            `2xl:pt-7 2xl:pb-4`
          )}
        >
          {title}
        </p>
        <p
          className={classnames(
            `text-2xs text-citrine-gray-dark`,
            `md:text-xs`,
            `lg:text-base`,
            `xl:text-xl`,
            `2xl:text-2xl`
          )}
        >
          {description}
        </p>
        {descriptionNote && (
          <p
            className={classnames(
              `text-2xs text-citrine-gray-dark mt-3`,
              `md:text-xs md:mt-4`,
              `lg:text-sm lg:mt-5`,
              `xl:text-sm xl:mt-6`,
              `2xl:text-base`
            )}
          >
            {descriptionNote}
          </p>
        )}
      </div>
    </div>
  )
}
