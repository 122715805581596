import classnames from "classnames"
import React, { ReactElement } from "react"

interface Props {
  title: string
}

export function HomeTitle({ title }: Props): ReactElement {
  return (
    <h1
      className={classnames(
        `heading__1 flex justify-center mt-8 mb-5 text-citrine-primary-dark`,
        `sm:text-2xl sm:mt-9 sm:mb-6`,
        `md:text-3xl md:mt-11 md:mb-8`,
        `lg:text-4xl lg:mt-16 lg:mb-10`,
        `xl:text-5xl xl:mt-20 xl:mb-12`,
        `2xl:text-6xl 2xl:mt-24 2xl:mb-16`
      )}
    >
      {title}
    </h1>
  )
}
