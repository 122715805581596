import classnames from "classnames"
import React, { ReactElement } from "react"

interface Props {
  intro: string
}

export function HomeIntro({ intro }: Props): ReactElement {
  return (
    <div
      className={classnames(
        `flex justify-center`,
        `sm:px-4 sm:block`,
        `md:px-7`,
        `lg:px-14`,
        `xl:px-20`
      )}
    >
      <p
        className={classnames(
          `leading-normal text-base max-w-11/12`,
          `sm:max-w-none`,
          `lg:text-lg lg:leading-relaxed`,
          `xl:text-xl xl:leading-relaxed`
        )}
      >
        {intro}
      </p>
    </div>
  )
}
