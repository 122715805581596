import { CharitiesQuery } from "@/../gatsby-graphql"

export function getSEODescription(
  pageHeader?: Pick<
    CharitiesQuery["charitiesWeSupportJson"]["pageHeader"],
    "top" | "bottom"
  > | null
) {
  return (
    pageHeader &&
    `${pageHeader.top ? pageHeader.top : ``} ${
      pageHeader.bottom ? pageHeader.bottom : ``
    }`
  )
}

export function getSEOImage(
  featuredCharitiesCarousel?:
    | CharitiesQuery["charitiesWeSupportJson"]["featuredCharitiesCarousel"]
    | null
) {
  return (
    featuredCharitiesCarousel &&
    featuredCharitiesCarousel.length &&
    featuredCharitiesCarousel[0].backgroundImage
  )
}
