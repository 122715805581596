import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { Hero } from "@components/Hero"
import { GiftsDescription } from "@components/GiftsDescription"
import { GiftsQualifications } from "@components/GiftsQualifications"
import { IPreviewContext } from "@context/previewContext"

import { GiftsQuery } from "@/../gatsby-graphql"
import { getSEODescription, getSEOImage } from "../utils/matchedGiftProgram"
import ContextWrapper from "./ContextWrapper"

type Props = PageProps<GiftsQuery> & IPreviewContext

function GiftsPage({ location, data, ...rest }: Props) {
  const {
    description,
    giftQualifications,
    hero,
    fields: { slug },
  } = data.matchedGiftProgramJson
  return (
    <ContextWrapper {...rest}>
      <Layout location={location}>
        <SEO
          title="Gifts"
          description={getSEODescription(description)}
          image={getSEOImage(hero)}
          slug={slug}
        />
        {hero && <Hero {...hero} />}
        <div className="container">
          {description && <GiftsDescription description={description} />}
          {giftQualifications && (
            <GiftsQualifications {...giftQualifications} />
          )}
        </div>
      </Layout>
    </ContextWrapper>
  )
}

export default GiftsPage
