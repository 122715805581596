import classnames from "classnames"
import { Link } from "gatsby"
import React, { ReactElement, useContext } from "react"
import { AboutJsonValuesCards } from "@/../gatsby-graphql"
import { PreviewContext } from "@context/previewContext"

interface Props extends AboutJsonValuesCards {
  withMarginBottom?: boolean
}

export function ValuesCard({
  color,
  image,
  text,
  withMarginBottom,
  anchorLinkId,
}: Props): ReactElement {
  const { previewMode, getAsset } = useContext(PreviewContext)
  return (
    <Link
      to={`/our-grant-process#${anchorLinkId}`}
      className="hover:scale-105 transform"
      title={text}
    >
      <div
        className={classnames(
          {
            "mb-6": withMarginBottom,
          },
          `md:mb-0`
        )}
      >
        {color && (
          <div
            className={classnames(
              {
                "bg-citrine-blue-dark": color === `blue`,
                "bg-citrine-turquoise-dark": color === `cyan`,
                "bg-citrine-orange-dark": color === `orange`,
                "bg-citrine-purple-dark": color === `purple`,
              },
              `w-full h-1`,
              `sm:h-2`,
              `md:h-1`,
              `xl:h-2`
            )}
          />
        )}
        <div
          className={classnames(
            `bg-white text-citrine-gray-medium font-bold h-36 w-36 p-4 pr-2`,
            `sm:h-48 sm:w-48 sm:p-5 sm:pr-3`,
            `md:h-40 md:w-40 md:p-4`,
            `lg:h-48 lg:w-48`,
            `xl:h-64 xl:w-64 xl:p-7`,
            `2xl:h-72 2xl:w-72 2xl:p-9`
          )}
          key={text}
        >
          <img
            className={classnames(
              `h-10 w-10`,
              `sm:h-14 sm:w-14`,
              `md:h-10 md:w-10`,
              `lg:h-14 lg:w-14`,
              `xl:h-15 xl:w-15`,
              `2xl:h-20 2xl:w-20`
            )}
            src={
              previewMode ? getAsset(image?.src) : image?.src?.publicURL || ``
            }
            alt={image?.alt || ``}
          />
          <p
            className={classnames(
              `text-2xs mt-3`,
              `sm:text-sm`,
              `md:text-xs`,
              `lg:text-sm`,
              `xl:text-base xl:mt-4`,
              `2xl:text-lg 2xl:mt-5`
            )}
          >
            {text}
          </p>
        </div>
      </div>
    </Link>
  )
}
