import React, { ReactElement } from "react"
import { CharitiesWeSupportJsonCharitiesList } from "@/../gatsby-graphql"
import { CharitiesListItem } from "./CharitiesListItem"

interface Props {
  charitiesList: CharitiesWeSupportJsonCharitiesList
}

export function CharitiesList({ charitiesList }: Props): ReactElement {
  return (
    <div className="w-full">
      {charitiesList.values.map(
        (value, i) =>
          value && (
            <CharitiesListItem
              // eslint-disable-next-line react/no-array-index-key
              key={value.description + i}
              {...value}
              isReversed={i % 2 === 1}
            />
          )
      )}
    </div>
  )
}
