import * as React from "react"
import { PageProps } from "gatsby"
import { AboutQuery } from "@/../gatsby-graphql"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { Hero } from "@components/Hero"
import { AboutCitrineLogo } from "@components/AboutCitrineLogo"
import { AboutSummary } from "@components/AboutSummary"
import { Description } from "@components/Description"
import { Mission } from "@components/Mission"
import { ValuesCards } from "@components/ValuesCards"
import { BODAndStaff } from "@components/BODAndStaff"
import { IPreviewContext } from "@context/previewContext"
import ContextWrapper from "./ContextWrapper"
import { aboutHeroImage } from "./about.module.scss"

type Props = PageProps<AboutQuery> & IPreviewContext

function AboutPage({ data, location, ...rest }: Props) {
  const {
    hero,
    description,
    summary,
    mission,
    staff,
    valuesCards,
    headshotImage,
    fields: { slug },
  } = data.aboutJson
  return (
    <ContextWrapper {...rest}>
      <Layout location={location}>
        <SEO
          title="About"
          description={summary}
          image={hero && hero.image}
          slug={slug}
        />
        {hero && <Hero {...hero} imageClassName={aboutHeroImage} />}
        <div className="container">
          <AboutCitrineLogo />
          {summary && <AboutSummary summary={summary} />}
          {description && (
            <Description
              description={description}
              headshotImage={headshotImage}
            />
          )}
        </div>
        {mission && <Mission {...mission} />}
        {valuesCards && <ValuesCards valuesCards={valuesCards} />}
        {staff && <BODAndStaff {...staff} />}
      </Layout>
    </ContextWrapper>
  )
}

export default AboutPage
