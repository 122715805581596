import React, { ReactElement } from "react"
import classnames from "classnames"

import { MatchedGiftProgramJsonDescription } from "@/../gatsby-graphql"

interface Props {
  description: MatchedGiftProgramJsonDescription[]
}

export function GiftsDescription({ description }: Props): ReactElement {
  return (
    <div
      className={classnames(
        `p-8 text-citrine-gray-dark`,
        `sm:pt-10`,
        `md:px-20`,
        `lg:px-32 lg:pt-12`,
        `xl:px-44 xl:pt-14`,
        `2xl:pt-16`
      )}
    >
      {description.map(paragraph => (
        <p
          key={paragraph.paragraph}
          className={classnames(
            `pb-3 last:pb-0 text-sm font-medium leading-normal`,
            `md:leading-normal md:text-base`,
            `lg:leading-snug lg:text-lg lg:pb-6`,
            `xl:leading-snug xl:text-xl xl:pb-8`,
            `2xl:leading-snug 2xl:text-2xl`
          )}
        >
          {paragraph.paragraph}
        </p>
      ))}
    </div>
  )
}
