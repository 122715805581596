import { GiftsQuery } from "@/../gatsby-graphql"

export function getSEODescription(
  description?: GiftsQuery["matchedGiftProgramJson"]["description"] | null
) {
  return (
    description && description.map(paragraph => paragraph.paragraph).join(` `)
  )
}

export function getSEOImage(
  hero?: GiftsQuery["matchedGiftProgramJson"]["hero"] | null
) {
  return hero && hero.image
}
