import { PreviewContext } from "@context/previewContext"
import { ImageSharp } from "@/../gatsby-graphql"
import classnames from "classnames"
import { GatsbyImageProps } from "gatsby-plugin-image"
import React, { ReactElement, useContext } from "react"
import {
  gatsbyImageWrapperConstrained,
  gatsbyImageWrapper,
} from "./styles.module.scss"

interface Props extends Omit<GatsbyImageProps, "image"> {
  image?:
    | {
        childImageSharp?: Pick<ImageSharp, "gatsbyImageData">
        publicURL?: string
      }
    | string
}

function getImage(imageSrc?: string, alt?: string) {
  const img = new Image()
  img.src = imageSrc
  img.alt = alt
  return img
}

function GatsbyImagePreview({
  className,
  image,
  alt,
  ...rest
}: Props): ReactElement {
  const { getAsset } = useContext(PreviewContext)
  const imageSrc = getAsset(image)
  const img = getImage(imageSrc, alt)
  const { height, width } = img
  return (
    <div
      data-gatsby-image-wrapper=""
      className={classnames(
        className,
        gatsbyImageWrapperConstrained,
        gatsbyImageWrapper
      )}
    >
      <div style={{ maxWidth: `1000px`, display: `block` }}>
        <img
          alt=""
          role="presentation"
          aria-hidden="true"
          src={`data:image/svg+xml;charset=utf-8,%3Csvg height='${height}' width='${width}' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E`}
          style={{ maxWidth: `100%`, display: `block`, position: `static` }}
          {...rest}
        />
      </div>
      <img
        aria-hidden="true"
        data-placeholder-image
        decoding="async"
        src={img.src}
        width={width}
        height={height}
        alt=""
        style={{
          opacity: 1,
          transition: `opacity 500ms linear 0s`,
          objectFit: `cover`,
        }}
        {...rest}
      />
      <picture>
        <source srcSet={img.src} media="(min-width: 0px)" />
        <img
          data-main-image=""
          decoding="async"
          width={width}
          height={height}
          src={img.src as string}
          alt={img.alt}
          style={{ objectFit: `cover` }}
          {...rest}
        />
      </picture>
      <noscript />
    </div>
  )
}

export default GatsbyImagePreview
