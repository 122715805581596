import classnames from "classnames"
import React from "react"
import { ContactUsJson } from "@/../gatsby-graphql"
import {
  sendButtonShadow,
  sendButtonDisabled,
} from "./ContactUsSendButton.module.scss"

interface Props {
  sendButton: ContactUsJson["sendButton"]
  disabled?: boolean
  onClick?: () => void
}

export function ContactUsSendButton({
  sendButton,
  disabled = false,
  onClick,
}: Props) {
  return (
    <div className={classnames(`flex justify-center`, `sm:justify-start`)}>
      <button
        type="submit"
        onClick={onClick}
        disabled={disabled}
        className={classnames(
          {
            [sendButtonShadow]: !disabled,
            [sendButtonDisabled]: disabled,
            "bg-citrine-gray-dark": disabled,
            "bg-citrine-primary-medium": !disabled,
            "shadow-none": disabled,
            "hover:shadow": !disabled,
            "hover:cursor-default": disabled,
          },
          `py-2 px-12 hover:shadow text-white font-bold text-base`,
          `md:py-2 md:px-10 md:text-base`,
          `lg:px-12`,
          `xl:px-14`,
          `2xl:text-lg`
        )}
      >
        {sendButton}
      </button>
    </div>
  )
}
