import classnames from "classnames"
import React, { ReactElement } from "react"

interface Props {
  summary: string
}

export function AboutSummary({ summary }: Props): ReactElement {
  return (
    <h4
      className={classnames(
        `text-citrine-primary-medium font-semibold mb-5 text-sm mx-5`,
        `sm:leading-relaxed sm:text-sm sm:mx-15`,
        `md:text-base md:leading-relaxed md:mx-16`,
        `lg:text-lg lg:leading-relaxed lg:mb-6 lg:mx-24`,
        `xl:text-xl xl:leading-9 xl:mx-28 xl:mb-10`,
        `2xl:text-2xl 2xl:leading-11 2xl:mx-36`
      )}
    >
      {summary}
    </h4>
  )
}
