import * as React from "react"

import { IPreviewContext, PreviewContext } from "@context/previewContext"

interface Props extends IPreviewContext {
  children: JSX.Element
}

function ContentWrapper({ previewMode = false, getAsset, children }: Props) {
  return (
    <PreviewContext.Provider value={{ previewMode, getAsset }}>
      {children}
    </PreviewContext.Provider>
  )
}

export default ContentWrapper
