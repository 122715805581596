import React, { ReactElement } from "react"
import Markdown from "react-markdown"
import classnames from "classnames"
import { Disclosure } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/solid"

import { OurGrantProcessJsonExclusions } from "@/../gatsby-graphql"
import { listItemFormatting, disclosure } from "./styles.module.scss"

interface Props {
  exclusions: OurGrantProcessJsonExclusions
}

export function GrantsExclusions({ exclusions }: Props): ReactElement {
  return (
    <Disclosure
      as="div"
      className={classnames(
        `bg-citrine-gray-dark text-citrine-accent font-bold text-xs py-4 px-10`,
        `sm:flex sm:items-start sm:px-16`,
        `md:px-20`,
        `lg:text-sm`,
        `xl:px-28 xl:text-base xl:py-8`,
        `2xl:text-lg 2xl:px-36`
      )}
    >
      {({ open }) => (
        <>
          <div
            className={classnames(
              `w-full flex items-center justify-center`,
              `sm:w-fit-content sm:block`
            )}
          >
            {exclusions.icon &&
              exclusions.icon.src &&
              exclusions.icon.src.publicURL && (
                <div
                  className={classnames(
                    `w-20 h-14 m-5`,
                    `sm:w-24 sm:m-0 sm:p-4 sm:px-6`,
                    `md:w-28`,
                    `lg:w-36 lg:p-2 lg:px-8`,
                    `xl:w-40  xl:p-2 xl:px-6`,
                    `2xl:w-55 2xl:p-2 2xl:px-16`,
                    open ? `sm:h-24 md:h-28 lg:h-36 xl:h-40 2xl:h-55` : ``
                  )}
                >
                  <img
                    className={classnames(`w-full h-full`)}
                    src={exclusions.icon.src.publicURL}
                    alt={exclusions.icon.alt}
                  />
                </div>
              )}
          </div>
          <div
            className={classnames(
              `sm:w-auto sm:mt-3 sm:pr-5`,
              `md:pr-20`,
              `lg:pr-36`,
              `xl:pr-40`,
              `2xl:pr-55`
            )}
          >
            <Disclosure.Button
              as="div"
              className={classnames(
                disclosure,
                `flex mb-1 items-center cursor-pointer`
              )}
            >
              {exclusions.title && (
                <h2 className="mr-2">
                  <Markdown
                    linkTarget="_blank"
                    className="markdown exclusion text-xs lg:text-sm xl:text-base"
                  >
                    {exclusions.title}
                  </Markdown>
                </h2>
              )}
              <ChevronUpIcon
                className={classnames(`w-7 h-7`, {
                  "transform rotate-180": open,
                })}
              />
            </Disclosure.Button>
            <Disclosure.Panel>
              {exclusions.list && (
                <ul className={classnames(listItemFormatting, `mr-10`)}>
                  {exclusions.list.map(
                    (exclusion, i) =>
                      exclusion && (
                        <li className="mb-1" key={exclusion.listItem || i}>
                          {exclusion.listItem}
                        </li>
                      )
                  )}
                </ul>
              )}
            </Disclosure.Panel>
          </div>
        </>
      )}
    </Disclosure>
  )
}
