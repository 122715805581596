import React, { ReactElement } from "react"
import { DotProps } from "react-multi-carousel"
import classnames from "classnames"

function CharitiesCarouselDot({
  active,
  onClick,
  index,
}: DotProps): ReactElement {
  return (
    <li>
      <button
        type="button"
        value={index}
        tabIndex={0}
        aria-label={`slide item ${index + 1}`}
        onKeyPress={({ key }) => {
          if ([` `, `Enter`].includes(key) && onClick) {
            onClick()
          }
        }}
        onClick={onClick}
        className={classnames(
          `cursor-pointer w-4 h-4 rounded-full inline-block border-2 border-citrine-gray-medium hover:bg-citrine-gray-medium`,
          { "bg-citrine-gray-medium": active, "bg-transparent": !active }
        )}
      />
    </li>
  )
}

export default CharitiesCarouselDot
