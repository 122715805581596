import React, { useContext } from "react"
import classnames from "classnames"

import { PreviewContext } from "@context/previewContext"
import { OurGrantProcessJsonCharitiesListValues } from "@/../gatsby-graphql"
import CustomGatsbyImage from "../CustomGatsbyImage/CustomGatsbyImage"

interface Props extends OurGrantProcessJsonCharitiesListValues {
  isReversed: boolean
  isColoured: boolean
  gradient: string
}

export function GrantsCharitiesListItem({
  description,
  icon,
  image,
  title,
  isReversed,
  isColoured,
  gradient,
  anchorLinkId,
}: Props) {
  const { getAsset, previewMode } = useContext(PreviewContext)
  return (
    <div
      className={classnames(`flex flex-col-reverse w-full`, {
        "sm:flex-row-reverse": isReversed,
        "sm:flex-row": !isReversed,
        "sm:text-right": isReversed,
        "sm:justify-end": isReversed,
      })}
      id={anchorLinkId}
    >
      <div
        className={classnames(`h-full sm:w-1/2 sm:flex-grow sm:flex-shrink-0`)}
      >
        <CustomGatsbyImage
          className={classnames(`h-full w-full`)}
          image={image.src}
          alt={image.alt}
        />
      </div>
      {isColoured && (
        <div
          className={classnames(
            gradient,
            `w-3 h-auto hidden`,
            `sm:block sm:flex-shrink-0`
          )}
        />
      )}
      <div
        className={classnames(
          `flex flex-col justify-center px-6 pb-4 pt-6`,
          {
            "sm:items-end": isReversed,
          },
          `sm:w-1/2 sm:flex-grow sm:flex-shrink-0`,
          `lg:p-10 lg:py-5`,
          `xl:px-12 xl:py-6`
        )}
      >
        <img
          className={classnames(
            `w-20 h-20 mb-2`,
            `sm:w-16 sm:h-16 sm:mb-1`,
            `md:w-20 md:h-20`,
            `lg:w-24 lg:h-24`,
            `xl:w-28 xl:h-28 xl:mb-3`,
            `2xl:w-32 2xl:h-32`
          )}
          src={previewMode ? getAsset(icon?.src) : icon?.src?.publicURL || ``}
          alt={icon?.alt}
        />
        {title && (
          <p
            className={classnames(
              `text-2xl mb-2 leading-tight font-extrabold`,
              `sm:text-base sm:mb-1 sm:leading-none`,
              `md:text-lg md:leading-tight`,
              `lg:text-2xl lg:leading-tight`,
              `xl:text-3xl xl:leading-tight`,
              `2xl:text-4xl 2xl:mb-3 2xl:leading-tight`
            )}
          >
            {title}
          </p>
        )}
        {description && (
          <p
            className={classnames(
              `text-base text-citrine-gray-dark leading-tight`,
              `sm:text-2xs`,
              `md:text-xs md:leading-tight`,
              `lg:text-base lg:leading-snug`,
              `xl:text-lg xl:leading-snug`,
              `2xl:text-2xl 2xl:leading-normal`
            )}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  )
}
