import React, { ReactElement } from "react"
import classnames from "classnames"
import { HomeJsonCallToAction } from "@/../gatsby-graphql"
import { LinkButton } from "../Button"
import { contactUsSection } from "./homeStyles.module.scss"

type Props = HomeJsonCallToAction

export function HomeContactUsSection({
  content,
  linkLocation,
}: Props): ReactElement {
  return (
    <div
      className={classnames(
        contactUsSection,
        `h-28 w-full flex items-center justify-center relative z-10`,
        `sm:h-40`,
        `md:h-52`,
        `lg:h-65`
      )}
    >
      <LinkButton withShadow type="secondary" to={linkLocation}>
        {content}
      </LinkButton>
    </div>
  )
}
