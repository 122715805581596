import { ImageSharp } from "@/../gatsby-graphql"

export interface Image {
  src?: {
    childImageSharp?: Pick<ImageSharp, "gatsbyImageData">
    publicURL?: string
  }
  alt?: string
}

export function doesImageExist(image?: Image["src"]) {
  return (
    !!image &&
    !!image.childImageSharp &&
    !!image.childImageSharp.gatsbyImageData
  )
}
