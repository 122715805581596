import React from "react"
import classnames from "classnames"
import Recaptcha from "react-google-recaptcha"
import { useContactUsForm } from "@hooks/useContactUsForm"
import { ContactUsJson } from "@/../gatsby-graphql"
import { ContactUsSendButton } from "@components/ContactUsSendButton"
import { FormControl, FormError } from "@components/FormControl"

interface Props {
  sendButton: ContactUsJson["sendButton"]
}

export function ContactUsForm({ sendButton }: Props) {
  const {
    buttonDisabled,
    enableForm,
    formError,
    formSent,
    handleFileChange,
    handleSubmit,
    handleTextChange,
    recaptchaRef,
    state,
  } = useContactUsForm()

  return (
    <div className={classnames(`sm:pl-6 sm:w-6/10`, `lg:pl-8`, `xl:pl-10`)}>
      <form
        method="POST"
        data-netlify="true"
        name="contact-us"
        data-netlify-recaptcha="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="w-full"
      >
        <div className={classnames(`sm:flex sm:justify-between`)}>
          <input type="hidden" name="form-name" value="contact-us" />
          <FormControl
            {...state.name}
            className="sm:w-48%"
            onChange={handleTextChange}
            name="name"
            placeholder="Your Name"
            required
          />
          <FormControl
            {...state.email}
            className="sm:w-48%"
            errorClassName={classnames(`sm:ml-2`, `2xl:ml-3`)}
            onChange={handleTextChange}
            name="email"
            placeholder="Your Email"
            required
          />
        </div>
        <FormControl
          {...state.subject}
          onChange={handleTextChange}
          name="subject"
          placeholder="Your Subject"
          required
        />
        <FormControl
          {...state.message}
          type="textarea"
          onChange={handleTextChange}
          name="message"
          placeholder="Your Message"
          inputClassName={classnames(
            `min-h-32`,
            `sm:min-h-0 sm:mb-0`,
            `md:min-h-24`,
            `lg:min-h-32`
          )}
        />
        <div className="mb-2">
          <label className="file-input" htmlFor="file">
            Upload File:{` `}
            <input
              className="file-input"
              type="file"
              name="file"
              onChange={handleFileChange}
            />
          </label>
        </div>
        <Recaptcha
          ref={recaptchaRef}
          sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY || `no_key`}
          size="normal"
          onChange={enableForm}
          // id="recaptcha-google"
        />
        {sendButton && (
          <div className="flex items-center content-center mt-1">
            <ContactUsSendButton
              disabled={buttonDisabled}
              sendButton={sendButton}
            />
            {formSent && (
              <h3
                className={classnames(
                  `text-citrine-gray-medium px-2 text-2xs font-bold`,
                  `sm:text-xs`,
                  `lg:px-3`,
                  `xl:text-base`,
                  `2xl:px-4`
                )}
              >
                Thank you! Your message has been sent successfully.
              </h3>
            )}
            {formError && (
              <FormError
                error={`We're sorry, but an error has occurred. Please check the
              form and try again. Error: ${formError.message}`}
                className={classnames(`px-2`, `lg:px-3`, `2xl:px-4`)}
              />
            )}
          </div>
        )}
      </form>
    </div>
  )
}
