import React, { ReactElement } from "react"
import classNames from "classnames"
import { Carousel } from "@components/Carousel"
import CharitiesCarouselDot from "@components/Carousel/CharitiesCarouselDot"
import { useMediaQuery } from "@hooks/useMediaQuery"
import { Image } from "../../utils/image"
import { charitiesCarouselShadow } from "./styles.module.scss"
import { CarouselSlideItem } from "./CarouselSlideItem"

interface Props {
  charitiesCarousel: { image: Image }[]
}

const responsive = {
  largeDesktop: {
    breakpoint: { max: 5000, min: 1536 },
    items: 7,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: { max: 1535, min: 1024 },
    items: 5,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1023, min: 640 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}

function useDeviceType(): keyof typeof responsive {
  const { is2xl, isLarge, isMedium, isMobile, isSmall, isXl } = useMediaQuery()
  switch (true) {
    case is2xl:
      return `largeDesktop`
    case isXl:
    case isLarge:
      return `desktop`
    case isMedium:
    case isSmall:
      return `tablet`
    case isMobile:
    default:
      return `mobile`
  }
}

export function CharitiesCarousel({ charitiesCarousel }: Props): ReactElement {
  const deviceType = useDeviceType()
  return (
    <div
      className={classNames(
        charitiesCarouselShadow,
        `z-20 flex flex-col justify-around relative`
      )}
    >
      <Carousel
        responsive={responsive}
        deviceType={deviceType}
        customDot={<CharitiesCarouselDot />}
      >
        {charitiesCarousel.map(({ image }) => (
          <CarouselSlideItem
            key={`charities-carousel-${image.src}-${image.alt}`}
            image={image}
          />
        ))}
      </Carousel>
    </div>
  )
}
