import classnames from "classnames"
import React, { ReactElement } from "react"
import { HomeJsonLinkCards } from "@/../gatsby-graphql"
import { LinkCards } from "../LinkCards"

type Props = HomeJsonLinkCards

export function LinkCardsSection({ cards, title }: Props): ReactElement {
  return (
    <div className="flex text-center justify-center flex-col">
      <h2
        className={classnames(
          `text-citrine-primary-medium italic text-2xl`,
          `md:text-3xl`,
          `xl:text-4xl`,
          `2xl:text-5xl`
        )}
      >
        {title}
      </h2>
      {cards && <LinkCards linkCards={cards} />}
    </div>
  )
}
