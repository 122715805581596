import React, { ReactElement } from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import {
  linkButtonBoxShadow,
  linkButtonBoxShadowHover,
} from "./LinkButton.module.scss"

interface Props {
  to: string
  children: React.ReactChild[] | React.ReactChild
  className?: string
  type?: "primary" | "secondary"
  withShadow?: boolean
  differentTab?: boolean
}

function LinkButton({
  to,
  children,
  className,
  type = `primary`,
  withShadow = false,
  differentTab = false,
}: Props): ReactElement {
  const LinkElement = to.match(/https:\/\/|mailto:/)?.length ? `a` : Link
  return (
    <div className={classnames(`flex justify-center`, className)}>
      <LinkElement
        className={classnames(
          {
            [linkButtonBoxShadow]: withShadow,
            [linkButtonBoxShadowHover]: !withShadow,
            "bg-citrine-primary-medium": type === `primary`,
            "text-citrine-black": type === `primary`,
            "bg-white": type === `secondary`,
            "text-citrine-gray-dark": type === `secondary`,
          },
          `heading__2 text-base px-10 py-3 hover:text-black focus:text-black`,
          `sm:text-base sm:px-10 sm:py-3`,
          `md:text-lg md:px-14 md:py-5`,
          `lg:px-14 lg:py-5`,
          `xl:text-xl xl:px-16 xl:py-6`,
          `2xl:text-2xl 2xl:px-20 2xl:py-8`
        )}
        to={to}
        href={to}
        target={differentTab ? `_blank` : undefined}
      >
        {children}
      </LinkElement>
    </div>
  )
}

export default LinkButton
