import classnames from "classnames"
import React, { ReactElement } from "react"
import Markdown from "react-markdown"
import { AboutJsonMission } from "@/../gatsby-graphql"
import { missionBoxShadow } from "./missionStyles.module.scss"

type Props = AboutJsonMission

export function Mission({ body, title }: Props): ReactElement {
  return (
    <div
      className={classnames(
        missionBoxShadow,
        `mt-8 px-4 py-8 relative z-20`,
        `sm:px-7 sm:py-12`,
        `md:px-7 md:py-14 md:mt-10`,
        `lg:py-20 lg:mt-12`,
        `xl:h-100 xl:mt-14 xl:px-14`,
        `2xl:h-100 2xl:mt-16 2xl:px-16`
      )}
    >
      <div className="container flex flex-col justify-center items-center">
        {title && (
          <h4
            className={classnames(
              `text-citrine-primary-medium font-bold mb-1`,
              `sm:mb-2 sm:text-2xl`,
              `lg:text-4xl lg:mb-3`,
              `xl:mb-3`,
              `2xl:text-5xl 2xl:mb-4`
            )}
          >
            {title}
          </h4>
        )}
        {body && (
          <h2
            className={classnames(
              `max-w-5xl`,
              `sm:text-3xl`,
              `md:text-4xl`,
              `lg:text-5xl lg:max-w-5xl`,
              `2xl:max-w-6xl`
            )}
          >
            <Markdown
              linkTarget="_blank"
              className={classnames(
                `markdown colored-italics text-center px-6`,
                `sm:px-0`
              )}
            >
              {body}
            </Markdown>
          </h2>
        )}
      </div>
    </div>
  )
}
