import { createRef, useState } from "react"
import Recaptcha from "react-google-recaptcha"
import { Input } from "@utils"

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export interface State {
  name: Input
  email: Input
  subject: Input
  message: Input
  file?: File
}

const formValidation: Record<
  keyof State,
  { required?: boolean; regex?: RegExp; regexError?: string }
> = {
  email: {
    required: true,
    regex: emailRegex,
    regexError: `Must be a valid email`,
  },
  name: {
    required: true,
  },
  subject: {
    required: true,
  },
  file: {},
  message: {},
}

export function useContactUsForm() {
  const [state, setState] = useState<State>({
    name: { value: `` },
    email: { value: `` },
    subject: { value: `` },
    message: { value: `` },
    file: undefined,
  })
  const [formSent, setFormSent] = useState<boolean>(false)
  const [formError, setFormError] = useState<Error | undefined>(undefined)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const recaptchaRef = createRef<Recaptcha>()

  function validateField(
    fieldName: keyof State,
    value: string
  ): string | undefined {
    const { regex, regexError, required } = formValidation[fieldName]
    if (required && !value) {
      return `Required`
    }
    if (regex && !value.match(regex)) {
      return regexError
    }
    return undefined
  }

  function validateForm() {
    const { email, name, subject } = state
    const emailError = validateField(`email`, email.value)
    const nameError = validateField(`name`, name.value)
    const subjectError = validateField(`subject`, subject.value)
    if (emailError || nameError || subjectError) {
      setState(oldState => ({
        ...oldState,
        email: {
          ...oldState.email,
          error: emailError,
        },
        name: {
          ...oldState.name,
          error: nameError,
        },
        subject: {
          ...oldState.subject,
          error: subjectError,
        },
      }))
      return true
    }
    return false
  }

  function disableForm() {
    setButtonDisabled(true)
  }
  function enableForm() {
    setButtonDisabled(false)
  }

  function handleTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      [e.target.name]: {
        value: e.target.value,
        error: validateField(e.target.name as keyof State, e.target.value),
      },
    })
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, file: e.target.files[0] })
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (buttonDisabled) return
    if (validateForm()) {
      const errorInput = document.querySelector(
        `input.error`
      ) as HTMLInputElement
      if (errorInput) {
        errorInput.focus()
      }
      return
    }
    setFormError(undefined)

    const recaptchaValue = recaptchaRef.current
      ? recaptchaRef.current.getValue()
      : undefined

    const stateData = {
      "form-name": `contact-us`,
      "g-recaptcha-response": recaptchaValue,
      name: state.name.value.trim(),
      email: state.email.value.trim(),
      subject: state.subject.value.trim(),
      message: state.message.value.trim(),
      file: state.file,
    }

    const formData = new FormData()

    Object.entries(stateData).forEach(([key, value]) => {
      formData.append(key, value)
    })
    fetch(`/`, {
      method: `POST`,
      body: formData,
    })
      .then(() => {
        setFormError(undefined)
        setButtonDisabled(true)
        setFormSent(true)
      })
      .catch(error => setFormError(error))
  }
  return {
    formSent,
    formError,
    handleSubmit,
    handleTextChange,
    handleFileChange,
    recaptchaRef,
    state,
    buttonDisabled,
    enableForm,
    disableForm,
  }
}
