import * as React from "react"
import { PageProps } from "gatsby"
import classnames from "classnames"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { HeroCarousel } from "@components/HeroCarousel"
import { HomeTitle } from "@components/HomeTitle"
import { HomeIntro } from "@components/HomeIntro"
import { LinkButton } from "@components/Button"
import { LinkCardsSection } from "@components/LinkCardsSection"
import { CharitiesCarousel } from "@components/CharitiesCarousel"
import { HomeContactUsSection } from "@components/HomeContactUsSection"

import { RootQuery } from "@/../gatsby-graphql"
import { IPreviewContext } from "@context/previewContext"
import { homeGradient } from "./home.module.scss"
import { Image } from "../utils/image"
import ContextWrapper from "./ContextWrapper"

type Props = PageProps<RootQuery> & IPreviewContext

function HomePage({ data, location, ...rest }: Props) {
  const {
    callToAction,
    carouselImages,
    intro,
    title,
    linkCards,
    charitiesCarousel,
    contactUsButton,
  } = data.homeJson
  return (
    <ContextWrapper {...rest}>
      <Layout location={location}>
        <SEO title="Home" />
        <HeroCarousel items={carouselImages} />
        <div className={homeGradient}>
          <div className="container">
            <div className="pb-4 sm:pb-6 md:pb-10 lg:pb-12 xl:pb-20">
              <HomeTitle title={title} />
              <HomeIntro intro={intro} />
              {callToAction && (
                <LinkButton
                  className={classnames(
                    `mb-6 mt-9`,
                    `md:mb-8 md:mt-11`,
                    `lg:mb-10 lg:mt-14`,
                    `xl:mb-11 xl:mt-16`
                  )}
                  to={callToAction.linkLocation}
                >
                  {callToAction.content}
                </LinkButton>
              )}
              {linkCards && <LinkCardsSection {...linkCards} />}
            </div>
          </div>
        </div>
        {charitiesCarousel && (
          <CharitiesCarousel
            charitiesCarousel={charitiesCarousel as { image: Image }[]}
          />
        )}
        {contactUsButton && <HomeContactUsSection {...contactUsButton} />}
      </Layout>
    </ContextWrapper>
  )
}

export default HomePage
