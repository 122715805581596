import React, { ReactElement, useContext } from "react"
import classnames from "classnames"
import Logo from "@assets/Citrine-Logo.svg"
import { PreviewContext } from "@context/previewContext"

export function AboutCitrineLogo(): ReactElement {
  const { previewMode } = useContext(PreviewContext)
  return (
    <div
      className={classnames(
        `flex justify-center items-center mt-5 mb-4`,
        `sm:mt-7 sm:mb-6`,
        `md:mt-10 sm:mb-8`,
        `lg:mt-12 sm:mb-10`,
        `xl:mt-14 xl:mb-11`,
        `xl:mt-17 xl:mb-14`
      )}
    >
      <div
        className={classnames(
          `w-44`,
          `sm:w-52`,
          `md:w-64`,
          `lg:w-72`,
          `xl:w-96`,
          `2xl:w-120`
        )}
      >
        {previewMode ? (
          <img src={(Logo as unknown) as string} alt="" />
        ) : (
          <Logo />
        )}
      </div>
    </div>
  )
}
