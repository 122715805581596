import classnames from "classnames"
import React, { ReactElement } from "react"
import { AboutJsonValuesCards } from "@/../gatsby-graphql"
import { ValuesCard } from "../ValuesCard"
import { valuesCardsSection } from "./valuesCardsStyles.module.scss"

interface Props {
  valuesCards: AboutJsonValuesCards[]
}

export function ValuesCards({ valuesCards }: Props): ReactElement {
  return (
    <div className={classnames(valuesCardsSection)}>
      <div className="container flex relative z-10 gap-6 flex-grow flex-wrap py-7 xl:py-16 justify-evenly">
        {valuesCards.map(
          card => card && <ValuesCard key={card.text + card.color} {...card} />
        )}
      </div>
    </div>
  )
}
